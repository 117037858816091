/* Example Styles for React Tags*/
/* .app {
    padding: 40px;
    text-align: center;
} */

/* Example Styles for React Tags*/

/* .container {
    margin: auto;
    width: 50%;
} */

.ReactTags__tags {
    position: relative;
}

.ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
    border-radius: 2px;
    /* display: inline-block; */
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 14px;
    border: 1px solid #eee;
    min-width: 150px;
}

.ReactTags__editInput {
    border-radius: 1px;
}

.ReactTags__editTagInput {
    display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected {
    text-align: left;
    margin-bottom: 10px;
}

.ReactTags__selected span.ReactTags__tag {
    opacity: 1;
    cursor: move;
    background-color: #00284ecc;
    font-size: 16px;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
}

.ReactsTags-FormControl {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}