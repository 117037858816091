/* :root {
    --white: #fff;
    --background: #f0f2f5;
    --hover-shadow: #f2f2f2;
    --hover-shadow-2: #e4e6e9;
    --border: #ccc;
    --box-shadow: rgba(0, 0, 0, 0.2);
    --reactions-links: #65676b;
    --black1: #1c1e21;
    --black2: #050505;
    --green: #2f9a48;
} */

.Editor {
    /* background-color: rgb(241, 242, 242); */
}

.wrap {
    display: flex;
    height: 100%;
}

.one {
    display: flex;
    /* position: fixed; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 250px;
    background: rgb(235, 235, 235);
    height: 100%;
    padding: 10px 20px 10px 20px;
    border-right: 2px solid rgba(43, 41, 46, 0.08);
}

.two {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    /* align-items: center; */
    flex: 1 1;
    /* padding: 100px 20px 30vh; */
}

@media (max-width: 767px) {
    .wrap {
        flex-direction: column;
    }

    .one {
        height: 450px;
    }

    .two {
        padding-left: 20px;
    }

    .one,
    .two {
        width: auto;
        position: inherit;
    }
}