body {
    /* width: 100%;
    background: #fafafa;
    position: relative; */
    font-family: 'roboto', sans-serif;
}

.social-media {
    display: inline-flex;
    align-items: center;

    color: #2F393E;
    height: 38px;
    padding: 0 6px;
    font-size: 0.8125rem;
    min-width: 116px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 19px;
    letter-spacing: 0.02857em;
    text-transform: none;

    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
    border-width: 2px;

    height: 40px;
    border-color: #E6EAF6;
    border-width: 1px;
}

.content {
    font-size: 19px;
    font-weight: 400;
    line-height: 1.5;
    color: #2c2f34;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", "Open Sans", Arial, sans-serif;
}

.reloadIcon {
    font-size: 40px;
    color: #f1f2f2;
    opacity: 65%;
}