/***************
 * BASE STYLES * 
 ***************/

/* .FacePost * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 15px;
    max-width: 570px;
} */

:root {
    --white: #fff;
    --background: #f0f2f5;
    --hover-shadow: #f2f2f2;
    --hover-shadow-2: #e4e6e9;
    --border: #ccc;
    --box-shadow: rgba(0, 0, 0, 0.2);
    --reactions-links: #65676b;
    --black1: #1c1e21;
    --black2: #050505;
    --green: #2f9a48;
}

.FacePost html {
    height: 100%;
    /* font-size: 62.5%; */
}

.FacePost body {
    background: var(--background);
    min-height: 100%;
    /* font-size: 1.6rem; */
    font-family: "Segoe UI", Helvetica, Arial, Helvetica, sans-serif;
}

.FacePost a {
    text-decoration: none;
    color: inherit;
}

.FacePost .postcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 2rem; */
}

/***************
   * POST HEADER * 
   ***************/

.FacePost .post {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 15px;
    max-width: 570px;

    background: var(--white);
    width: 100%;
    max-width: 50rem;
    padding-bottom: 5rem;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
}

.post__header {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1.6rem 0 1.6rem;
}

/* Header left */
.FacePost .header__left {
    flex: 1;
}

.FacePost .author__name:hover,
.FacePost .post__date:hover {
    text-decoration: underline;
}

.FacePost .author__verified,
.FacePost .post__privacy,
.FacePost .options__icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url(./images/icons-list-1.png);
    background-size: 7.1rem 43.2rem;
    background-repeat: no-repeat;
    background-position: -1.7rem -38.6rem;
}

.FacePost .author__name {
    font-size: 15px;
    margin-right: 0.2rem;
}

.FacePost .post__author-pic {
    float: left;
    width: 4rem;
    height: 4rem;
    margin-right: 0.8rem;
    border-radius: 50%;
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
    transition: filter 0.2s ease;
}

.FacePost .post__author-pic:hover {
    filter: brightness(0.95);
}

.FacePost .post__author {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--black2);
}

.FacePost .post__date {
    display: block;
    float: left;
    font-size: 13px;
    color: #65676b;
}

/* Dot between post author and post date */
.FacePost .post__date-privacy-separator {
    position: relative;
    bottom: 0.1rem;
}

.FacePost .post__privacy {
    background-image: url(./images/icons-list-2.png);
    background-size: 2.6rem 89.4rem;
    background-position: 0 -78.2rem;
    opacity: 0.6;
}

/* Header right */
.FacePost .post__options {
    padding: 1rem;
    border-radius: 50%;
    line-height: 50%;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.FacePost .post__options:hover {
    background: var(--hover-shadow);
}

.FacePost .options__icon {
    height: 2rem;
    width: 2rem;
    position: relative;
    background-size: 7.4rem 43rem;
    background-position: -2.2rem -24rem;
    opacity: 0.6;
}

/****************
   * POST CONTENT * 
   ****************/

.FacePost .content__paragraph {
    /* font-size: 1.5rem; */
    font-family: system-ui;
    font-size: 15px;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
}

.FacePost .content__image {
    width: 100%;
    /* height: 33rem; */
    object-fit: cover;
    display: block;
}

.FacePost .content__image:hover {
    cursor: pointer;
}

/***************
   * POST FOOTER * 
   ***************/

/* REACTIONS */
.FacePost .footer__reactions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.6rem;
    color: var(--reactions-links);
    /* font-size: 1.5rem; */
}

.FacePost .reactions__emojis {
    display: flex;
    align-items: center;
}

.FacePost .emojis__like,
.FacePost .emojis__haha,
.FacePost .emojis__love {
    width: 1.8rem;
    height: 1.8rem;
    position: relative;
    cursor: pointer;
}

.FacePost .emojis__like {
    z-index: 2;
}

.FacePost .emojis__haha {
    z-index: 1;
    left: -0.2rem;
}

.FacePost .emojis__love {
    left: -0.4rem;
}

.FacePost .emojis__count {
    margin-left: 0.3rem;
}

.FacePost .comment-shares__shares {
    margin-left: 0.2rem;
}

.FacePost .emojis__count:hover,
.FacePost .comment-shares__comments:hover,
.FacePost .comment-shares__shares:hover {
    text-decoration: underline;
}

/* BUTTONS (LIKE, COMMENT, SHARE) */
.FacePost .footer__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 1.2rem;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
}

.FacePost .buttons__like,
.FacePost .buttons__comment,
.FacePost .buttons__share {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 3.5rem;
    margin: 0.2rem 0;
    /* font-size: 1.5rem; */
    font-weight: 600;
    border-radius: 0.5rem;
    color: var(--reactions-links);
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.FacePost .buttons__like:hover,
.FacePost .buttons__comment:hover,
.FacePost .buttons__share:hover {
    background: var(--hover-shadow);
}

.FacePost .like__icon,
.FacePost .comment__icon,
.FacePost .share__icon {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-block;
    background-image: url("./images/icons-list-3.png");
    background-size: 2.6rem 77.4rem;
    background-repeat: no-repeat;
    opacity: 0.6;
    margin-right: 0.5rem;
}

.FacePost .like__icon {
    background-position: 0 -18rem;
}

.FacePost .comment__icon {
    background-position: 0 -14rem;
}

.FacePost .share__icon {
    background-position: 0 -20rem;
}

/* POST COMMENTS */
.FacePost .footer__comments {
    padding: 0.8rem 1.2rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: right;
    color: var(--reactions-links);
}

/* Comments filter */
.FacePost .comments__filter {
    display: inline-block;
    margin-bottom: 0.8rem;
    cursor: pointer;
}

.FacePost .filter__icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url("./images/icons-list-4.png");
    background-size: 7.4rem 43.4rem;
    background-repeat: no-repeat;
    background-position: -5.4rem -36.4rem;
    opacity: 0.6;
    vertical-align: middle;
}

/* Write a comment.. box */
.FacePost .comments__box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.FacePost .box__profile {
    margin-right: 1rem;
    position: relative;
}

.FacePost .box__profile::after {
    content: "";
    display: block;
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    bottom: 0.3rem;
    left: 2.3rem;
    background: var(--green);
    border: 0.3rem solid var(--white);
    border-radius: 50%;
}

.FacePost .box__profile:hover {
    cursor: pointer;
}

.FacePost .profile__pic {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
}

.FacePost .box__bar {
    display: flex;
    align-items: center;
    flex: 1;
}

.FacePost .bar__input {
    width: 100%;
    background: var(--background);
    border: none;
    padding: 1.2rem;
    font-size: 1.5rem;
    color: rgba(5, 5, 5, 0.8);
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    outline: none;
    flex: 1;
}

.FacePost .bar__input::placeholder {
    opacity: 0.8;
}

.FacePost .bar__input:focus::-webkit-input-placeholder {
    opacity: 0.6;
}

.FacePost .bar__emojis {
    display: flex;
    align-items: center;
    height: 4.16rem;
    background: var(--background);
    padding-right: 1.2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.FacePost .emojis__insert,
.FacePost .emojis__attach,
.FacePost .emojis__gif,
.FacePost .emojis__sticker {
    border-radius: 50%;
    padding: 0.5rem;
    line-height: 0.5rem;
    margin-left: 0.3rem;
    cursor: pointer;
}

.FacePost .emojis__insert:hover,
.FacePost .emojis__attach:hover,
.FacePost .emojis__gif:hover,
.FacePost .emojis__sticker:hover {
    background: var(--hover-shadow-2);
}

.FacePost .insert__emoji,
.FacePost .attach__emoji,
.FacePost .gif__emoji,
.FacePost .sticker__emoji {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    background-image: url("./images/icons-list-3.png");
    background-size: 2.6rem 77.4rem;
    background-repeat: no-repeat;
    opacity: 0.6;
}

.FacePost .insert__emoji {
    background-position: 0 -32.8rem;
}

.FacePost .attach__emoji {
    background-position: 0 -27.4rem;
}

.FacePost .gif__emoji {
    background-position: 0 -34.6rem;
}

.FacePost .sticker__emoji {
    background-position: 0 -40rem;
}

/* Friends comments */
.FacePost .comments__friend-comment {
    display: flex;
    text-align: start;
}

.FacePost .friend-comment__pic {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    margin-right: 1rem;
    cursor: pointer;
    transition: filter 0.2s ease;
}

.FacePost .friend-comment__pic:hover {
    filter: brightness(0.95);
}

.FacePost .friend-comment__comment {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 0.5rem;
    padding: 0.8rem 1.2rem;
    background: var(--background);
    border-radius: 1.5rem;
}

.FacePost .comment__author {
    align-self: flex-start;
    color: var(--black2);
    font-size: 1.35rem;
}

.FacePost .comment__author:hover {
    text-decoration: underline;
}

.FacePost .comment__content {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--black2);
}

.FacePost .comment__reactions {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -1.5rem;
    right: 0;
    padding: 0.2rem 0.5rem;
    background: var(--white);
    box-shadow: 0 0.1rem 0.2rem var(--box-shadow);
    border-radius: 1rem;
    cursor: pointer;
}

.FacePost .reactions__emojis {
    width: 1.6rem;
    height: 1.6rem;
    position: relative;
}

.FacePost .reactions__like {
    z-index: 2;
}

.FacePost .reactions__haha {
    z-index: 1;
    left: -0.3rem;
}

.FacePost .reactions__love {
    left: -0.6rem;
}

.FacePost .reactions__count {
    position: relative;
    right: 0.1rem;
    font-size: 1.32rem;
    font-weight: 400;
    color: var(--reactions-links);
}

.FacePost .friend-comment__options {
    align-self: center;
    padding: 0.8rem;
    border-radius: 50%;
    line-height: 0.8rem;
    cursor: pointer;
}

.FacePost .friend-comment__options:hover {
    background: var(--hover-shadow);
}

.FacePost .options__comment {
    width: 1.6rem;
    height: 1.6rem;
    background-position: 0 -36.8rem;
    background-size: 7.4rem 44.6rem;
    background-image: url("./images/icons-list-5.png");
}

.FacePost .comment__links,
.FacePost .comment__replies {
    position: absolute;
}

/* Like, reply and comment date */
.FacePost .comment__links {
    bottom: -2rem;
    color: var(--reactions-links);
    font-size: 1.2rem;
    font-weight: 400;
}

.FacePost .comment__replies {
    display: flex;
    align-items: center;
    bottom: -4.5rem;
}

.FacePost .comment__replies:hover {
    cursor: pointer;
    text-decoration: underline;
}

.FacePost .links__like,
.FacePost .links__reply {
    font-weight: 700;
}

.FacePost .links__like:hover,
.FacePost .links__reply:hover,
.FacePost .links__date:hover {
    text-decoration: underline;
}

.FacePost .replies__emoji {
    width: 1.6rem;
    height: 1.6rem;
    background-image: url("./images/icons-list-6.png");
    background-position: -5.4rem -24.4rem;
    background-size: 7.4rem 32.2rem;
    background-repeat: no-repeat;
    display: inline-block;
    opacity: 0.6;
    margin-right: 0.5rem;
}

.FacePost .comments__more-comments {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: -5rem;
}

.FacePost .more-comments__link:hover {
    text-decoration: underline;
}

.FacePost .more-comments__count {
    font-weight: 400;
}